import request from '@/plugins/axios'

export function getAccountTask(params) {
  // 获取帐号落查任务
  return request({
    url: '/detect/account_tasks',
    method: 'get',
    params
  })
}
export function addAccountTask(data) {
  // 添加帐号落查任务
  return request({
    url: '/detect/account_tasks',
    method: 'post',
    data
  })
}

export function getPhoneTask(params) {
  // 获取邮箱落查任务
  return request({
    url: '/detect/email_tasks',
    method: 'get',
    params
  })
}
export function addPhoneTask(data) {
  // 添加手机号落查任务
  return request({
    url: '/detect/email_tasks',
    method: 'post',
    data
  })
}
